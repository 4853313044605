import "./SelectedProject.css"
import game from "../images/Uno-game.png"
import players from "../images/Uno-players.png"
import leader from "../images/Uno-leader.png"
import winner from "../images/Uno-winner.png"

const Uno = ({onClose}) => {

    const handleClose = () => {
        onClose()
    }

    return (
        <section className="selected-project">
            <header className="header">
                <h2 className="title">Uno-Stack-App-O</h2>
                <div id="page-links">
                    <a href="https://github.com/selsmie/code_cards_uno_project" target="_blank" rel="noreferrer" id="page-link">View on GitHub</a>
                </div>
            </header>
            <section className="brief">
                <h4>Brief</h4>
                <p>Create a simple card game modelled after Uno. The game will involve four suits(colours), with each suit containing the numbers 0 through to 9, adding individual card styling to create visual difference. This game will exclude any special cards. The game will allow from two to ten players, selected at time of play.</p>
            </section>
            <section className="mvp">
                <h4>MVP</h4>
                <ul>
                    <li>Add a minimum of two and a maximum of 10 players to the player list</li>
                    <li>Be able to distinguish different card types from card styling</li>
                    <li>Be able to play only the cards in their hand that adhere to the rules of the game</li>
                    <li>Be able to add to their card total by "picking up" cards from the deck</li>
                </ul>
            </section>
            <section className="extensions">
                <h4>Extensions</h4>
                <ul>
                    <li>Addition of special cards, including skips, reversals, pick-up cards and wild cards</li>
                    <li>Add a victory counter defined by player name. To be displayed in a leader board or chart</li>
                </ul>
            </section>

            <section className="images-container">
                <img src={players} alt="Uno player input screen" className="image" id="uno"/>
                <img src={game} alt="Uno in game screen" className="image" id="uno"/>
                <img src={winner} alt="Uno winners screen" className="image" id="uno"/>
                <img src={leader} alt="Uno leaderboard" className="image" id="uno"/>
            </section>
            <div className="close" onClick={handleClose}>
                <h5>Close</h5>
            </div>
        </section>
    );
};

export default Uno;
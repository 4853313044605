import "./SelectedProject.css"
import welcome from "../images/Honest-welcome.png"
import allres from "../images/Honest-all-res.png"

const Honest = ({onClose}) => {

    const handleClose = () => {
        onClose()
    }
    
    return (
    
        <section className="selected-project">
            <header className="header">
            <h2 className="title">Honest Hotel</h2>
            <div id="page-links">
                {/* <a href="https://honest-hotel.herokuapp.com" target="_blank" rel="noreferrer" id="page-link">View on Heroku</a> */}
                <a href="https://github.com/selsmie/honest-hotel" target="_blank" rel="noreferrer" id="page-link">View on GitHub</a>
            </div>
            </header>
            <section className="brief">
                <h4>Brief</h4>
                <p>Create a piece of software to help manage guests, rooms and reservations.</p>
            </section>
            <section className="mvp">
                <h4>MVP</h4>
                <ul>
                    <li>The app should allow the hotel to create and edit guest names</li>
                    <li>The app should allow the hotel to create extra rooms</li>
                    <li>The app should allow the hotel to assign a reservation to a room</li>
                    <li>The app should show a list all rooms and their assigned guests</li>
                    <li>The app should allow the hotel to check reservations out of rooms</li>
                </ul>
            </section>
            <section className="extensions">
                <h4>Extensions</h4>
                <ul>
                    <li>Add a date entry system to allow filtering of reservations by date</li>
                    <li>Be able to distinguish between returning guests and first timers</li>
                    <li>Add details to guests, including preferences, notes & memorable information</li>
                    <li>Create maximum occupancy for rooms to prevent more reservations being in one room at any time</li>
                </ul>
            </section>

            <section className="images-container">
            <img src={welcome} alt="Honest Hotel welcome screen" className="image"/>
            <img src={allres} alt="Honest Hotel reservations screen" className="image"/>
            </section>
            <div className="close" onClick={handleClose}>
                <h5>Close</h5>
            </div>
        </section>
    );
};

export default Honest;
import './App.css';
import HeaderContainer from "./containers/HeaderContainer"

function App() {
  return (
    <main className="App">
      <HeaderContainer/>
    </main>
  );
}

export default App;

import "./Projects.css"
import Honest from "../components/Honest"
import Budget from "../components/Budget"
import Uno from "../components/Uno"
import {useState} from "react"

const Projects = () => {
    
    const [honest, setHonest] = useState(false)
    const [uno, setUno] = useState(false)
    const [budget, setBudget] = useState(false)

    const honestClick = () => {
        setUno(false)
        setBudget(false)
        setHonest(true)
    }

    const unoClick = () => {
        setUno(true)
        setBudget(false)
        setHonest(false)
    }

    const budgetClick = () => {
        setUno(false)
        setBudget(true)
        setHonest(false)
    }

    const onClose = () => {
        setUno(false)
        setBudget(false)
        setHonest(false)
    }

    const displayHonest = (honest) ? <Honest onClose={onClose}/> : null
    const displayBudget = (budget) ? <Budget onClose={onClose}/> : null
    const displayUno = (uno) ? <Uno onClose={onClose}/> : null

    return (
        <main className="project-page">
            <section className="projects">
                <section className="python">
                    <h2 className="project-name">Honest Hotel</h2>
                    <h4 className="languages">Python | Flask | PostgreSQL</h4>
                    <h5 className="info" onClick={honestClick}>
                        <a href="#honest-quick-link" className="project-quick-links">More Info</a>
                    </h5>
                </section> 

                <section className="uno">
                    <h2 className="project-name">Uno-Stack-App-O</h2>
                    <h4 className="languages">JavaScript | Vue | MongoDB</h4>
                    <h5 className="info" onClick={unoClick}>
                    <a href="#uno-quick-link" className="project-quick-links">More Info</a></h5>
                </section> 

                <section className="budgetaway">
                    <h2 className="project-name">BudgetAway</h2>
                    <h4 className="languages">JavaScript | React | Java | Spring</h4>
                    <h5 className="info" onClick={budgetClick}>
                        <a href="#budget-quick-link" className="project-quick-links">More Info</a></h5>
                </section> 
            </section>
            <section className="single-project">
                <div id="honest-quick-link" >
                    {displayHonest}
                </div>
                <div id="uno-quick-link">
                    {displayUno}
                </div>
                <div id="budget-quick-link">
                    {displayBudget}
                </div>
            </section>
        </main>
    );
};

export default Projects;
import "./SelectedProject.css"
import home from "../images/Budget-home.png"
import flights from "../images/Budget-flights.png"
import wish from "../images/Budget-wish.png"

const Budget = ({onClose}) => {

    const handleClose = () => {
        onClose()
    }

    return (
        <section className="selected-project">
            <header className="header">
                <h2 className="title">BudgetAway</h2>
                <div id="page-links">
                    {/* <a href="http://budgetaway-react.herokuapp.com" target="_blank" rel="noreferrer" id="page-link">View on Heroku</a> */}
                    <a href="https://github.com/selsmie/budgetaway_react" target="_blank" rel="noreferrer" id="page-link">View on GitHub</a>
                </div>
            </header>
            <section className="brief">
                <h4>Brief</h4>
                <p>Create a travel application combing the React and Spring frameworks</p>
            </section>
            <section className="mvp">
                <h4>MVP</h4>
                <ul>
                    <li>Allow a user to select a country from a list</li>
                    <li>Allow a user to view details about the selected country</li>
                    <li>Allow the user to look up flights to the country</li>
                    <li>Set a current location, with the country being used to filter the flights look up</li>
                    <li>Allow the user to add and remove a trip to a favourites list</li>
                    <li>Display a price for a trip</li>
                    <li>Use a saving calculator to determine how long it would take to save for the trip based on an input monthly saving amount</li>
                </ul>
            </section>
            <section className="extensions">
                <h4>Extensions</h4>
                <ul>
                    <li>Update the saving calculator to take in a salary, deducting off regular expenses, to then provide the time required to save for the trip</li>
                    <li>Allow the savings required to be displayable by saving amount or time required</li>
                    <li>Allow the user to add a deposit to savings</li>
                    <li>Integrate a map to display when selecting a country</li>
                </ul>
            </section>

            <section className="images-container">
                <img src={home} alt="BudgetAway home page" className="image"/>
                <img src={flights} alt="Selected country and flight screen" className="image"/>
                <img src={wish} alt="Wishlist trips screen" className="image"/>
            </section>
            <div className="close" onClick={handleClose}>
                <h5>Close</h5>
            </div>
        </section>
    );
};

export default Budget;
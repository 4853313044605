import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import NavBar from "../components/NavBar"
import About from "../components/About"
import Projects from "./Projects"

const HeaderContainer = () => {
    return (
        <Router>
            <>
                <NavBar/>
                <Switch>
                <Route exact path="/">
                    <About/>
                </Route>
                <Route exact path="/projects">
                    <Projects/>
                </Route>
                </Switch>
            </>
        </Router>
    );
};

export default HeaderContainer;
import "./About.css"
import profile from "../images/Profile.jpeg"
import github from "../images/github.jpeg"
import linkedin from "../images/linkedin.png"
import react_icon from "../images/react.png"
import terraform from "../images/terraform.png"
import java from "../images/java.png"
import python from "../images/python.png"
import vue_icon from "../images/vue.png"

const About = () => {
    return (
        <main className="about-page">
            <section className="about-me">
                <div className="about">
                    <h2 id="title">About me</h2>
                    <p id="text">A DevSecOps Engineer with experience in creating custom tooling, automation functionality and pipeline vulnerability scanning. Passionate about driving the required cultural change to bring Security to the forefront and promote the “Shift Left” mentality.<br></br><br></br>Being a graduate in Chemistry and former luxury hospitality professional has allowed me to enhance key skills, such as logical and analytical thought processing, problem solving, service delivery and communication skills - both for people and client management, that I bring with me to every new challenge. I thrive in dynamic environments where teamwork and attention to detail are essential.</p>
                    <img src={profile} alt="Profile shot" id="profile"/>
                    <h3 id="email">Contact email: 
                        <a href="mailto:simon@simonelsmie.com" id="email"> simon@simonelsmie.com</a>
                    </h3>
                    
                    <section className="prof-link">
                        <a href="https://github.com/simonelsmie" target="_blank" rel="noreferrer">
                            <img src={github} alt="GitHub link" id="web-links"/>
                        </a>
                        <a href="https://www.linkedin.com/in/simonelsmie/" target="_blank" rel="noreferrer">
                            <img src={linkedin} alt="LinkedIn link" id="web-links"/>
                        </a>
                    </section>
                    
                </div>  
            </section>
            <section className="footer-flex">
                <section className="tech-languages">
                    <img src={python} alt="Python" className="logos"/>
                    <img src={terraform} alt="Terraform" className="logos"/>
                    <img src={react_icon} alt="Reactjs" className="logos"/>
                    <img src={vue_icon} alt="Vue.js" className="logos"/>
                    <img src={java} alt="Java" className="logos"/>
                </section>
            </section>
        </main>
    );
};

export default About;
import {Link} from "react-router-dom"
import "./NavBar.css"

const NavBar = () => {
    return (
        <div className="navbar-area">
            <h1 id="name">Simon Elsmie</h1>
            <div>
                <ul className="navbar">
                    <li>
                        <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                        <Link to="/">About</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default NavBar;